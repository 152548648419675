 /* .page-home .card-xl, .page-home .card-lg, .page-home .card-sm, .page-home .card-content-analytics {
    overflow: hidden;
    display: flex;
    align-items: center;
    align-content: center;
}  */
.card {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
    position: relative;
}
 .text {
    border: #1d2938 solid black;
} 
 .text-center {
    text-align: center;
} 

 .card-recent-courses, .card-recent-lessons {
    height: 235px;
} 

 .row {
    margin-left: -15px;
    margin-right: -15px;
} 
 .page-home .card-action .card-content {
    width: 220px;
    margin: auto;
} 
 .card .card-content {
    position: relative;
} 
 .card.card-action {
    cursor: pointer;
} 

.page-home .card-action, .page-home .card-action > a {
    text-decoration: none;
}
.card-content {
    text-align: center; 
}
.block-v-sm {
    padding-top: 4px;
    padding-bottom: 4px;
}
 ul, ol {
    margin-top: 0;
    margin-bottom: 12px;
    padding-left: 1.2em;
    list-style-type: none;
} 
ul {
    display: block;
    list-style-type: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.chart-legend-key {
    position: relative;
    padding-left: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-xxl {
    font-size: 64px;
}
.text-center {
    text-align: center;
}
.block-xl {
    padding: 24px;
}
.block-v-xl {
    padding-top: 24px;
    padding-bottom: 24px;
}
.react-card {
    background-color: #fff;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
    margin: 15px 0;
    position: relative;
}
.react-card .card-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    /* cursor: pointer; */
}
.react-card .card-content .card-thumb {
    min-width: 136px;
    height: 145px;
    background-color: #343a409c;
    background-position: center;
    background-size: cover;
    position: relative;

}
.react-card .card-content .card-text {
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 16px;
    height: 128px;
    overflow: hidden;
    position: relative;
    line-height: 1.3;
}

.hHdVrU {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.course-items-container {
    min-height: 600px;
    display: flex;
    
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.react-wrapper .course-card-item {
    margin: 15px;
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
    -ms-flex-item-align: center;
    align-self: center;
    z-index: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.input-error {
    color:red;
    display:none;
}
.highlight {
    color:red !important;
    font-weight: bold !important;
    font-size: 25px;
 }